<template>
  <b-card-code title="">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- kiri -->
          <b-col>
            <b-form-group label="Nama dan Gelar" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.nama_gelar"
                placeholder="Masukkan Nama dan Gelar"
              />
            </b-form-group>

            <b-form-group label="Email" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.email"
                placeholder="Masukkan Email"
              />
            </b-form-group>

            <b-form-group label="NIK" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.nik"
                type="number"
                placeholder="Masukkan NIK"
              />
            </b-form-group>

            <b-form-group label="Tempat Lahir" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.tempat_lahir"
                placeholder="Masukkan Tempat Lahir"
              />
            </b-form-group>

            <b-form-group label="Tanggal Lahir" label-for="name">
              <flat-pickr
                v-model="formSip.tanggal_lahir"
                class="form-control"
                placeholder="Pilih Tanggal Lahir"
                :config="configs.allowInput"
              />
            </b-form-group>

            <b-form-group label="Nama Profesi" label-for="name">
              <b-form-select
                placeholder="Nama Profesi"
                v-model="formSip.id_profesi"
                size="md"
                @change="profesiCheck()"
              >
                <option :value="null" disabled>Pilih Profesi</option>
                <option v-for="option in combo_profesi" :value="option.id">
                  {{ option.nama_profesi }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Nomor STR"
              label-for="name"
              v-show="this.showSTR === true"
            >
              <b-form-input
                id="name"
                v-model="formSip.nomor_str"
                placeholder="Masukkan Nomor STR"
              />
            </b-form-group>

            <b-form-group
              label="Penerbit STR"
              label-for="name"
              v-show="this.showSTR === true"
            >
              <b-form-input
                id="name"
                v-model="formSip.penerbit_str"
                placeholder="Masukkan Penerbit STR"
              />
            </b-form-group>

            <b-form-group
              label="Tanggal Terbit STR"
              label-for="name"
              v-show="this.showSTR === true"
            >
              <flat-pickr
                v-model="formSip.tanggal_terbit_str"
                class="form-control"
                placeholder="Pilih Tanggal Terbit"
                :config="configs.allowInput"
              />
            </b-form-group>

            <b-form-group label="STR Berlaku Sampai" label-for="name">
              <flat-pickr
                v-model="formSip.str_berlaku_sampai"
                class="form-control"
                placeholder="Pilih Tanggal STR Berlaku Sampai"
                :config="configs.allowInput"
              />
            </b-form-group>

            <b-form-group label="Faskes" label-for="name">
              <b-form-select
                placeholder="Faskes"
                v-model="formSip.id_faskes"
                size="md"
              >
                <option :value="null" disabled>Pilih Faskes</option>
                <option
                  v-for="option in combo_faskes"
                  :value="option.id_faskes"
                >
                  {{ option.nama_faskes }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Bagi yang sudah mempunyai SIP, masukkan semua nomor SIP aktif, dipisahkan dengan tanda koma"
              label-for="name"
            >
              <b-form-textarea
                v-model="formSip.nomor_sip_sudah_dimiliki"
                id="textarea-default"
                placeholder="Masukkan Semua Nomor SIP Aktif"
                rows="4"
              />
            </b-form-group>

            <!-- <b-form-group label="Nomor Rekomendasi OP" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.no_rek_op"
                placeholder="Masukkan Nomor Rekomendasi OP"
              />
            </b-form-group> -->
          </b-col>

          <!-- kanan -->
          <b-col>
            <b-form-group
              label="Praktik Mandiri / Praktek di Sarana Kesehatan"
              label-for="name"
            >
              <b-form-checkbox
                v-model="formSip.is_praktik_mandiri"
                value="true"
                plain
              >
                Praktik Mandiri
              </b-form-checkbox>

              <b-form-checkbox
                v-model="formSip.is_praktik_mandiri"
                value="false"
                plain
              >
                Praktek di Sarana Kesehatan
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Tempat Praktik" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.nama_tempat_praktik"
                placeholder="Masukkan Tempat Praktik"
              />
            </b-form-group>

            <b-form-group
              label="Cara Perawatan"
              label-for="name"
              v-show="showCaraPerawatan === true"
            >
              <b-form-input
                id="name"
                v-model="formSip.cara_perawatan"
                placeholder="Masukkan Cara Perawatan"
              />
            </b-form-group>

            <b-form-group label="Kecamatan" label-for="name">
              <b-form-select
                placeholder="Kecamatan"
                v-model="formSip.kecamatan"
                size="md"
                @change="getComboKelurahan()"
              >
                <option :value="null" disabled>Pilih Kecamatan</option>
                <option
                  v-for="option in combo_kecamatan"
                  :value="option.sub_district"
                >
                  {{ option.sub_district }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Kelurahan" label-for="name">
              <b-form-select
                placeholder="Kelurahan"
                v-model="formSip.kelurahan"
                size="md"
                @change="getKodePos()"
              >
                <option :value="null" disabled>Pilih Kelurahan</option>
                <option v-for="option in combo_kelurahan" :value="option.urban">
                  {{ option.urban }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Kode Pos" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.kode_pos"
                placeholder="Masukkan Kode Pos"
              />
            </b-form-group>

            <b-form-group label="Alamat Praktik Lengkap" label-for="name">
              <b-form-textarea
                v-model="formSip.alamat_praktik"
                id="textarea-default"
                placeholder="Masukkan RT/ RW dan Nama Jalan"
                rows="4"
              />
            </b-form-group>

            <b-form-group label="Koordinat Lokasi Praktik" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.koordinat_lokasi_praktik"
                placeholder="Masukkan Koordinat Lokasi Praktik"
              />

              <b-button
                class="mt-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="tutorialKoordinat()"
                size="sm"
              >
                Tutorial Cara Mendapatkan Titik Koordinat
              </b-button>
            </b-form-group>

            <b-form-group label="Waktu Praktik" label-for="name">
              <b-form-textarea
                v-model="formSip.waktu_praktik"
                id="textarea-default"
                placeholder="Masukkan Waktu Praktik"
                rows="4"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-form-checkbox v-model="isCheckboxChecked">
            Saya menyatakan dengan sesungguhnya bahwa semua data yang saya input
            serta lampiran adalah benar. Jika ada indikasi pemalsuan data, Saya
            bersedia diproses sesuai hukum yang berlaku.
          </b-form-checkbox>
        </b-row>

        <!-- button -->
        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="!isCheckboxChecked"
              variant="primary"
              @click="submitUpdateSIP"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },

      combo_profesi: [],
      combo_syarat: [],

      combo_faskes: [],

      isCheckboxChecked: false,

      showSTR: false,
      showCaraPerawatan: false,

      sip_stts: 0,

      hari_praktik_sementara: "",
      jam_praktik_sementara: "",
      jam_praktik_sementara_akhir: "",

      lastPathParams: "",

      disabledTrue: true,

      formSip: {
        nama_gelar: "",
        email: "",
        nik: "",
        id_pendaftar: localStorage.getItem("id_pendaftar"),
        id_profesi: null,
        nama_sip: "",
        nomor_str: "",
        catatan: "",
        is_praktik_mandiri: false,
        penerbit_str: "",
        tanggal_terbit_str: "",
        no_rek_op: "",
        nama_tempat_praktik: "",
        alamat_praktik: "",
        koordinat_lokasi_praktik: "",
        waktu_praktik: "",
        cara_perawatan: "",
        kecamatan: null,
        kelurahan: null,
        kode_pos: "",
        id_faskes: null,
        str_berlaku_sampai: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        nomor_sip_sudah_dimiliki: "",
      },

      id_sip: "",

      combo_kecamatan: [],
      combo_kelurahan: [],

      // end
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    this.formSip.id_pendaftar = localStorage.getItem("id_pendaftar");

    if (this.lastPathParams === "ubah") {
      this.getIdData();
    }

    this.getComboProfesi();
    this.getComboKecamatan();
    this.getComboFaskes();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    getComboFaskes() {
      return new Promise(() => {
        axios
          .get("pendaftar/list_faskes")
          .then((res) => {
            this.combo_faskes = res.data;
          })

          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });

              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    disabledSimpan() {
      // Toggle nilai disabledTrue ketika checkbox di-click
      this.disabledTrue = !this.disabledTrue;
    },

    getComboProfesi() {
      return new Promise(() => {
        axios
          .get("pendaftar/master_profesi")
          .then((res) => {
            this.combo_profesi = res.data;
          })

          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });

              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    getComboKecamatan() {
      return new Promise(() => {
        axios
          .get("admin/alamat_cek_kecamatan")
          .then((res) => {
            this.combo_kecamatan = res.data;
          })

          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });

              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    getComboKelurahan() {
      return new Promise(() => {
        axios
          .get("admin/alamat_cek_kelurahan?kecamatan=" + this.formSip.kecamatan)
          .then((res) => {
            this.combo_kelurahan = res.data;
          })

          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });

              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    getKodePos() {
      return new Promise(() => {
        axios
          .get("admin/alamat_cek_kelurahan?kecamatan=" + this.formSip.kecamatan)
          .then((response) => {
            this.combo_kelurahan = response.data;

            let indexKelurahan = this.combo_kelurahan.findIndex(
              (x) => x.urban === this.formSip.kelurahan
            );

            return new Promise(() => {
              axios
                .get(
                  "admin/alamat_cek_kelurahan?kecamatan=" +
                    this.formSip.kecamatan
                )
                .then((response) => {
                  this.formSip.kode_pos =
                    response.data[indexKelurahan].postal_code;
                });
            });
          });
      });
    },

    profesiCheck() {
      if (this.formSip.id_profesi === 24) {
        this.showSTR = false;
        this.showCaraPerawatan = true;
      } else if (this.formSip.id_profesi === 26) {
        this.showSTR = false;
        this.showCaraPerawatan = false;
      } else {
        this.showSTR = true;
        this.showCaraPerawatan = false;
      }

      return new Promise(() => {
        axios.get("pendaftar/master_profesi").then((response) => {
          this.combo_profesi = response.data;

          let indexProfesi = this.combo_profesi.findIndex(
            (x) => x.id === this.formSip.id_profesi
          );

          return new Promise(() => {
            axios.get("pendaftar/master_profesi").then((response) => {
              this.formSip.nama_sip = response.data[indexProfesi].nama_profesi;
            });
          });
        });
      });
    },

    getIdData() {
      return new Promise(() => {
        axios
          .get(
            "pendaftar/get_data_sip_by_id?id_sip=" + this.$route.params.idSIP
          )
          .then((res) => {
            this.formSip.id_pendaftar = res.data.id_pendaftar;
            this.formSip.id_profesi = res.data.id_profesi;
            this.formSip.nama_sip = res.data.nama_sip;
            this.formSip.nomor_str = res.data.nomor_str;
            this.formSip.catatan = res.data.catatan;

            this.formSip.nama_gelar = res.data.nama_gelar;
            this.formSip.email = res.data.email;
            this.formSip.nik = res.data.nik;

            this.formSip.str_berlaku_sampai = res.data.str_berlaku_sampai;
            this.formSip.id_faskes = res.data.id_faskes;

            this.formSip.penerbit_str = res.data.penerbit_str;
            this.formSip.tanggal_terbit_str = res.data.tanggal_terbit_str;
            this.formSip.no_rek_op = res.data.no_rek_op;
            this.formSip.nama_tempat_praktik = res.data.nama_tempat_praktik;
            this.formSip.alamat_praktik = res.data.alamat_praktik;
            this.formSip.koordinat_lokasi_praktik =
              res.data.koordinat_lokasi_praktik;
            this.formSip.waktu_praktik = res.data.waktu_praktik;
            this.formSip.cara_perawatan = res.data.cara_perawatan;
            this.formSip.kecamatan = res.data.kecamatan;
            this.formSip.kelurahan = res.data.kelurahan;
            this.formSip.kode_pos = res.data.kode_pos;

            this.formSip.tempat_lahir = res.data.tempat_lahir;
            this.formSip.tanggal_lahir = res.data.tanggal_lahir;

            this.formSip.nomor_sip_sudah_dimiliki =
              res.data.nomor_sip_sudah_dimiliki;

            this.getComboKelurahan();

            this.id_sip = res.data.id;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    submitUpdateSIP() {
      if (this.isCheckboxChecked) {
        // Proses data jika checkbox dicentang
        if (
          this.formSip.id_profesi === null ||
          this.formSip.nama_tempat_praktik === "" ||
          this.formSip.nama_tempat_praktik === null ||
          this.formSip.alamat_praktik === "" ||
          this.formSip.alamat_praktik === null ||
          this.formSip.koordinat_lokasi_praktik === "" ||
          this.formSip.koordinat_lokasi_praktik === null ||
          this.formSip.waktu_praktik === "" ||
          this.formSip.waktu_praktik === null
        ) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Inputan Belum Lengkap",
              icon: "XCircleIcon",
              variant: "danger",
            },
          });
        } else {
          if (this.lastPathParams === "tambah") {
            return new Promise(() => {
              axios
                .post("pendaftar/buat_sip", this.formSip)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data SIP Berhasil`,
                    },
                  });
                  this.$router.push("/pembuatan-sip");
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });

                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put(
                  "pendaftar/update_sip?id_sip=" + this.$route.params.idSIP,
                  this.formSip
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data SIP Berhasil`,
                    },
                  });
                  this.$router.push("/pembuatan-sip");
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });

                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          }
        }
      }
    },

    tutorialKoordinat() {
      window.open("https://www.youtube.com/watch?v=3nusyy1y6H0");
    },

    backButton() {
      this.$router.push("/pembuatan-sip");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

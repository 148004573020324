<template>
  <b-card-code title="" v-if="this.lastPathParams === 'ubah'">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="ID SIP" label-for="name">
                  <b-form-input v-model="formUpdateSIP.id" disabled />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Nama SIP" label-for="name">
                  <b-form-input v-model="formUpdateSIP.nama_sip" disabled />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Berkas Persyaratan" label-for="name">
                  <ul>
                    <li
                      v-for="item in this.formUpdateSIP.nama_berkas"
                      :key="item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-form-group label="List Persyaratan" label-for="name">
              <ul>
                <li v-for="item in this.berkas_persyaratan" :key="item">
                  {{ item }}
                </li>
              </ul>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },

      lastPathParams: "",
      formUpdateSIP: {
        is_praktik_mandiri: false,
        is_verifikasi_lapangan: false,
        is_cabut_sip: false,
      },
      berkas_persyaratan: [],

      formNotif: {
        email: "",
        pesan: "",
      },

      // end
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    if (this.lastPathParams === "ubah") {
      this.getIdData();
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    getIdData() {
      return new Promise(() => {
        axios
          .get(
            "pendaftar/get_data_sip_by_id?id_sip=" + this.$route.params.idSIP
          )
          .then((res) => {
            this.formUpdateSIP = res.data;
            this.berkas_persyaratan = res.data.berkas_persyaratan;

            this.formNotif.email = res.data.email;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    terbitSIP() {
      this.formUpdateSIP.status_sip = 1;
      this.$swal({
        title: "Terbitkan SIP ?",
        text: "Apakah anda yakin untuk menerbitkan SIP ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Terbitkan SIP",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.submitUpdateSIP();
        }
      });
    },

    tolakSIP() {
      this.formUpdateSIP.status_sip = 9;

      if (this.formUpdateSIP.catatan === "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Catatan Wajib Diisi",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        this.$swal({
          title: "Apakah anda yakin untuk menolak SIP ?",
          text: "Masukkan Catatan Terlebih Dahulu Untuk Menolak SIP",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Tolak SIP",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.submitUpdateSIP();
          }
        });
      }
    },

    submitUpdateSIP() {
      if (this.formUpdateSIP.is_praktik_mandiri === null) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:
              "Inputan Praktik Mandiri / Praktek di Sarana Kesehatan Masih Kosong",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        return new Promise(() => {
          axios
            .put(
              "admin/update_sip?id_sip=" + this.$route.params.idSIP,
              this.formUpdateSIP
            )
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ubah Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Ubah Data SIP Berhasil`,
                },
              });
              this.$router.push("/dashboard");
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });

                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
              }
            });
        });
      }
    },

    showModalNotif() {
      this.$refs["ref-form-notif"].show();
    },

    submitKirimNotif() {
      if (this.formNotif.email === "" || this.formNotif.pesan === "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Inputan Belum Lengkap",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        return new Promise(() => {
          axios
            .put(
              "notification/kirim_notif" + this.$route.params.idSIP,
              this.formNotif
            )
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Kirim Notif Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Kirim Notif SIP Berhasil`,
                },
              });

              this.getIdData();
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });

                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
              }
            });
        });
      }
    },

    kembaliNotif() {
      this.$refs["ref-form-notif"].hide();
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang sudah terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/ownership/" + this.id_ownership + "/" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Blok Berhasil`,
              },
            });
            this.getIdData();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    backButton() {
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
